<!-- 行业解决方案 1 -->
<template>
    <div class="solution1" id="jumpPoint">
        <div class="banner">
            <div class="banner_title">
                <p class="p1">汽&nbsp;&nbsp;车&nbsp;&nbsp;零&nbsp;&nbsp;部&nbsp;&nbsp;件&nbsp;&nbsp;行&nbsp;&nbsp;业&nbsp;&nbsp;解&nbsp;&nbsp;决&nbsp;&nbsp;方&nbsp;&nbsp;案</p>
                <p class="p2">超 越 软 件 智 造 未 来</p>
            </div>
        </div>

        <div class="boxes1">
            <p class="pub_title">简介</p>
            <div class="center">
                <div class="left">
                    <img src="./../../assets/Solution1/img1.png" alt="">
                </div>
                <div class="right">
                    <div class="line"></div>
                    <div class="content">{{content1}}</div>
                    <div class="line"></div>
                </div>
            </div>
        </div>

        <div class="boxes2">
            <p class="pub_title">问题现状</p>
            <div class="center">
                <div class="left">
                    <div v-html="content2" class="content"></div>
                </div>
                <div class="right">
                    <img src="./../../assets/Solution1/img2.png" alt="">
                </div>
            </div>
        </div>

        <div class="boxes3">
            <p class="pub_title">主要关注点</p>
            <div class="center">
                <div class="top">
                    <img src="./../../assets/Solution1/img3.png" alt="">
                </div>
                <div class="bottom">
                    <div v-for="(tip,index) in tip1" :key="index" class="per_tip">{{tip}}</div>
                </div>
            </div>
        </div>

        <div class="boxes4">
            <p class="pub_title">核心价值</p>
            <div class="center">
                <div class="area1">
                    <div v-for="(info,index) in infoes" :key="index" class="per">
                        <img :src="info.imgUrl" alt="">
                        <p>{{info.title}}</p>
                        <div>{{info.content}}</div>
                    </div>
                </div>
                <div class="area2">
                    <div v-for="(tip,index) in tip2" :key="index" class="per_tip">
                        <span>{{tip.trend}}</span>
                        <span>{{tip.content}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'Solution1',
    components:{
    },
    data(){
        return {
            content1:'随着汽车市场个性化消费理念的不断增强，全球汽车行业已经开始迈入大规模定制时代，为了满足个性化选配订单.个性化订单快速交付，汽车主机厂一般采用混合柔性JIS排序生产模式，从而要求零部件供应商也采用排序形式进行供货，零部件直接供应到主机厂生产者旁，且对质量追溯要求较高。',
            content2:'1、汽车零配件供应商对企业、工厂以及地区内的一些关键绩效指标难以进行实时的监控与管理，对出现的异常情况无法及时处理以及快速准确地判断故障原因;\n2、生产过程复杂，交货频繁，生产周期长，生产进度无法及时反馈，对管理人员依赖程度较大;\n3、需求计划变化快，插单频繁;\n4、生产节拍不稳定，严重影响下一个工作生产;\n5、在制品存量大;\n6、对产品追溯信息难以把控;',
            tip1:[
                '通过主机厂JIT系统接口获取生产队列，实现拉动生产河JIS生产',
                '提供内部生产计划管理机制，实现顺序生产、按序供货的 生产模式，使浪费和风险降到最低',
                '建立关键数据追溯档案，满足汽车零部件产品的追 溯要求',
                '建立在线质量控制系统， 进行不合格流程控制',
            ],
            infoes:[
                {
                    imgUrl:require("./../../assets/Solution1/img4.png"),
                    title:'更高的生产效率',
                    content:'可提高能源、生产物资设备、人员的使用效率',
                },
                {
                    imgUrl:require("./../../assets/Solution1/img5.png"),
                    title:'更高的柔性生产能力',
                    content:'可适应快速变化的市场，集成 各种不同的生产系统',
                },
                {
                    imgUrl:require("./../../assets/Solution1/img6.png"),
                    title:'更低的生产管理成本',
                    content:'更短的创新周期，更少的劳动力 投入，更短的制造周期',
                },
            ],
            tip2:[
                {
                    trend:'缩短25%',
                    content:'可缩短25%以上生产周期',
                },
                {
                    trend:'95%以上',
                    content:'障生产全数据，完整性95%以上',
                },
                {
                    trend:'减少80%',
                    content:'过程统计、记录工作量减少80%',
                },
                {
                    trend:'减少70%',
                    content:'生产过程质量过失可减少70%',
                },
                {
                    trend:'提高99%',
                    content:'产品品质在线报告，实时性提高99%',
                },
                {
                    trend:'提高20%',
                    content:'工厂整体生产绩效可提高20%以上',
                },
            ],
        }
    },
    
    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    },
}
</script>

<style scoped lang='scss'>
@import './Solution1.scss'
</style>
